@import '@doctoroncallcom/dermis-web/dist/style.css';
@import 'colors.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Bottom Scroll Bar class for all tables - START */
.table-custom-bottom-scroll::-webkit-scrollbar {
  height: 15px;
}

.table-custom-bottom-scroll::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-bottom: 5px solid white;
  border-top: 5px solid white;
}

.table-custom-bottom-scroll::-webkit-scrollbar-thumb {
  background: #0072eb8c;
  border-radius: 8px;
}

.table-custom-bottom-scroll::-webkit-scrollbar-thumb:hover {
  background: #0071eb;
}
/* Custom Bottom Scroll Bar class for all tables - END */

/* Custom Bottom Horizontal Slim Gray Scroll Bar class for horizontal scrollable components - START */
.custom-hori-slim-scroll-bar-class::-webkit-scrollbar {
  height: 6px;
}

.custom-hori-slim-scroll-bar-class::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.custom-hori-slim-scroll-bar-class::-webkit-scrollbar-thumb {
  background: #aaadb0;
  border-radius: 11px;
}

.custom-hori-slim-scroll-bar-class::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Custom Bottom Horizontal Slim Gray Scroll Bar class for horizontal scrollable components - END */

/* Custom Bottom Verticle Slim Gray Scroll Bar class for verticle scrollable components - START */
.custom-ver-slim-scroll-bar-class::-webkit-scrollbar {
  /* height: 4px; */
  width: 8px;
}

.custom-ver-slim-scroll-bar-class::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.custom-ver-slim-scroll-bar-class::-webkit-scrollbar-thumb {
  background: #aaadb0;
  border-radius: 11px;
}

.custom-ver-slim-scroll-bar-class::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Custom Bottom Verticle Slim Gray Scroll Bar class for verticle scrollable components - END */
